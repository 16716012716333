import { useEffect } from "react";
import api from "../../api";
import { useBetterState } from "../../hooks/use-better-state";
import tasks from "../../utils/tasks";

interface LogsViewProps {
  id: number;
}

interface Log {
  entidade: string;
  action: string;
  data: string;
  author: string;
  email: string;
  action_data: any;
}

const LogsView = ({ id }: LogsViewProps) => {
  const logs = useBetterState<any[] | undefined>([]);

  useEffect(() => {
    loadLogs();
  }, []);

  const loadLogs = () => {
    api.get(
      {
        path: "/api/resources/logs",
        params: {
          id: id,
        },
      },
      (data) => {
        const newData = data;
        logs.value = newData;
      },
      (errors) => { },
    );
  };

  console.log(logs.value)

  return (
    <div className="px-[80px]">
      <p className=" font-bold text-[16px]">Relátorio de Ações</p>
      {logs?.value?.map((item) => {
        return (
          <div className=" flex flex-col">
            <p>{`Nome : ${item.author} | Email : ${item.email} | Data : ${tasks.convertToGMTPlus2(item.data)} | Ação : ${item.action}`}</p>
            {(item.action === "Actualização" && tasks.verificarValor(item.action_data, '-') != '-') && (
              <div className="flex flex-col ml-[40px]">
                <table className="min-w-full divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-extrabold text-gray-500 uppercase tracking-wider ">Campo</th>
                      <th className="px-6 py-3 text-left text-xs font-extrabold text-gray-500 uppercase tracking-wider">Valor Antigo</th>
                      <th className="px-6 py-3 text-left text-xs font-extrabold text-gray-500 uppercase tracking-wider">Valor Actual</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {(JSON.parse(item.action_data).antigos as CampoAtualizado[]).map((item2, index) => {
                      console.log(item);
                      if (item2.campo == "id" || item2.campo == "data_registro") return null;
                      return (
                        <tr key={index} className={`${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}>
                          <td className="px-6 py-4 whitespace-nowrap font-medium">
                            {transformarSnakeCaseEmFrase(item2.campo)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">{tasks.verificarValor(item2.valor, '-')}</td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {tasks.verificarValor((JSON.parse(item.action_data).atualizados as CampoAtualizado[])?.[index].valor, '-')}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

              </div>
            )}
            <hr></hr>
          </div>
        );
      })}
    </div>
  );
};

interface CampoAtualizado {
  campo: string;
  valor: any;
}

function transformarSnakeCaseEmFrase(texto: string): string {
  return texto
    .trim() // Remove espaços no início e no fim
    .split("_") // Divide o texto nas palavras separadas por "_"
    .map((palavra) => palavra.charAt(0).toUpperCase() + palavra.slice(1)) // Converte a primeira letra de cada palavra em maiúscula
    .join(" "); // Junta as palavras com espaços
}

export default LogsView;
