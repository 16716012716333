import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../api";
import ImageView from "../../components/client/image";
import CosButton from "../../components/CosButton";
import LabelInfo from "../../components/label-info";
import { DataPagened } from "../../types/utils";
import tasks from "../../utils/tasks";

interface NotificacoesProps {
  id?: number;
  foto_bilhete: any;
  foto_residencia: any;
  observacoes: string;
  latitude: string;
  longitude: string;
  created_at?: string;
}

const Notificacoes = () => {
  const [not, setNot] = useState([]);

  const [notificacoes, setNotificações] = useState<
    DataPagened<NotificacoesProps>
  >({
    data: [],
    pagination: {
      currentPage: 1,
      totalPages: 1,
    },
  });

  useEffect(() => {
    loadNotificacoes(1);
  }, []);

  const deleteNot = (id: number) => {
    api.delete(
      {
        path: "/api/notificacoes",
        params: {
          id: id,
        },
      },
      (data) => {},
      (error) => {},
    );
  };

  const loadNotificacoes = (page: number) => {
    setNotificações({
      ...notificacoes,
      data: [],
    });
    api.get(
      {
        path: "/api/notificacoes",
        params: {
          page: page,
        },
      },
      (data) => {
        const newState2 = {
          ...data,
          info: {
            status: null,
          },
        };
        console.log(notificacoes.pagination);
        setNotificações(newState2);
      },
      (error) => {},
    );
  };

  console.log(notificacoes);

  return (
    <div className="w-full px-[10px] md:px-[15px] py-[8px] relative">
      <div className=" bg-blue-100 flex flex-1 flex-col elevated min-h-[300px]">
        <div className="flex w-full px-[40px] pt-[20px] flex-col">
          <div className="flex flex-1 flex-row justify-between flex-wrap">
            <div className="flex flex-1">
              <span className=" text-[20px] font-bold">
                Lista de Notificações
              </span>
            </div>
          </div>
        </div>
        <div className=" flex flex-row px-[40px] flex-wrap justify-around gap-[15px] py-[15px]">
          {notificacoes.data.map((item) => {
            return (
              <CardNotificacao
                latitude={item.latitude}
                longitude={item.longitude}
                observacoes={item.observacoes}
                foto_bilhete={item.foto_bilhete}
                foto_residencia={item.foto_residencia}
                created_at={item.created_at}
              />
            );
          })}
        </div>
        <div className="flex flex-row justify-center">
          <DataPaginationComponent
            totalPages={notificacoes.pagination.totalPages}
            currentPage={notificacoes.pagination.currentPage}
            handleGo={(page) => {
              loadNotificacoes(page);
            }}
          />
        </div>
      </div>
    </div>
  );
};

const CardNotificacao = ({
  id,
  foto_bilhete,
  foto_residencia,
  observacoes,
  latitude,
  longitude,
  created_at,
}: NotificacoesProps) => {
  const [img, setImage] = useState(foto_bilhete);

  return (
    <div className="max-w-[500px] min-h-[300px] flex flex-col items-center border-[2px] border-black-900">
      <div className=" relative">
        <ImageView name={img} maxHeight={400} />
        <div className=" absolute bottom-[5px] left-[5px] flex flex-row gap-[15px]">
          <button
            onClick={() => {
              setImage(foto_bilhete);
            }}
            className=" bg-blue-900 px-[15px] rounded-[5px]"
          >
            <span className=" text-[#fff] font-bold">Bilhete</span>
          </button>
          <button
            onClick={() => {
              setImage(foto_residencia);
            }}
            className=" bg-blue-900 px-[15px] rounded-[5px]"
          >
            <span className=" text-[#fff] font-bold">Residência</span>
          </button>
        </div>
      </div>
      <div className="w-full px-[20px] py-[15px]">
        <LabelInfo
          title="Data"
          value={tasks.convertToGMTPlus2(created_at ?? "") ?? ""}
        />
        <LabelInfo title="Informação" value={observacoes} />
        <div className="w-full flex flex-row justify-center pt-[15px]">
          <Link
            to={`https://www.google.com/maps?q=${latitude},${longitude}&t=m`}
            onClick={(e) => {
              e.preventDefault();
              window.open(
                `https://www.google.com/maps?q=${latitude},${longitude}&t=m`,
                "_blank",
              );
            }}
          >
            <CosButton>DIREÇÕES NO GOOGLE MAP</CosButton>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Notificacoes;

interface DataPaginationComponentProps {
  currentPage?: number;
  totalPages?: number;
  handleGo?: (page: number) => void;
  handleError?: (mgs: string) => void;
}

const DataPaginationComponent = ({
  currentPage = 1,
  totalPages = 1,
  handleGo,
  handleError,
}: DataPaginationComponentProps) => {
  const [cosPage, setCosPage] = useState("");

  const handleAction = () => {
    // lógica de ação
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginTop: "20px",
        alignItems: "center",
        backgroundColor: "#fff",
        padding: "10px",
        borderRadius: "10px",
      }}
    >
      <button
        onClick={() => {
          if (currentPage === 1) {
            return;
          }
          const page = currentPage - 1;
          if (page <= 1) {
            handleGo?.(page);
          }
        }}
        style={{
          backgroundColor: "#FF6666",
          borderRadius: "4px",
          height: "50px",
          padding: "10px 20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {"<"}
      </button>
      <span
        style={{
          fontSize: "20px",
          fontFamily: "Inter, sans-serif",
          color: "#0F4C75",
          padding: "0 15px",
        }}
      >
        {currentPage}
        <span style={{ fontSize: "13px" }}> {` In ` + totalPages}</span>
      </span>
      <button
        onClick={() => {
          if (currentPage === totalPages) {
            return;
          }
          const page = currentPage + 1;
          if (page > 1) {
            handleGo?.(page);
          }
        }}
        style={{
          backgroundColor: "#FF6666",
          borderRadius: "4px",
          height: "50px",
          padding: "10px 20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {">"}
      </button>
    </div>
  );
};
