import { useNavigate } from "react-router-dom";
import api from "../../api";
import { HTTP_STATUS } from "../../api/api-response-status";
import { useBetterState } from "../../hooks/use-better-state";
import useViewLog from "../../hooks/use-view-log";
import { User } from "../../types/usuario";
import useAppInfo from "../../zustand/app-info-zus";

const AddUserViewModel = () => {
  const { user, setUser, setLogout } = useAppInfo();

  const isLoading = useBetterState<boolean>(false);
  const { viewLog, setNewLog } = useViewLog();

  const navigate = useNavigate();

  const submitAddAccount = (userForm: User) => {
    const user = {
      ...userForm,
      senha: "1234567",
    };
    isLoading.value = true;
    api.post(
      {
        path: "/api/user/signup",
        data: user,
      },
      (data) => {
        isLoading.value = false;
        setNewLog({
          type: "SUCCESS",
          message: "Usuário Adicionado com sucesso com senha 1234567.",
        });
      },
      (error) => {
        isLoading.value = false;
        if (error.status === HTTP_STATUS.NETWORK_ERROR) {
          setNewLog({
            type: "WARNING",
            message: "Erro de conexão de internet.",
          });
        } else {
          console.log(error)
          setNewLog({
            type: "WARNING",
            message: "Erro inesperado, se persistir contacte o administrador.",
          });
        }
      },
    );
  };

  return {
    user,
    isLoading,
    viewLog,
    setNewLog,
    submitAddAccount,
  };
};

export default AddUserViewModel;
