import { Navigate, Outlet, useNavigate } from "react-router-dom";
import LeftNav from "../../components/menu/left-menu";
import NavBar from "../../components/menu/nav-bar";
import useAppInfo from "../../zustand/app-info-zus";

function BaseSecurityNav() {
  const navigate = useNavigate();
  const { user } = useAppInfo();

  return user !== undefined ? (
    <div className="flex flex-row">
      <LeftNav />
      <div className="w-full">
        <NavBar />
        <Outlet />
      </div>
    </div>
  ) : (
    <Navigate to={"/authentication"} />
  );
}

export default BaseSecurityNav;
