import axios, { AxiosInstance } from "axios";
import SessionStorage from "../constants/session-storage";
import { HTTP_STATUS } from "./api-response-status";

export const DEV_DELAY_HTTP_REQUEST = 0;
export const BASE_URL = "http://cadastro.adrmm.co.mz:3000";
export const API_MAP_KEY = "AIzaSyB5v8QIe5AcXYGeGwZuGUcnZh_21ZyBHxM";

const apiClient: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,OPTIONS,PATCH,DELETE,POST,PUT",
    "Access-Control-Allow-Headers":
      "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version, Authorization",
  },
});

export const googleDrivePhoto = (id: string): string => {
  return "https://drive.google.com/uc?id=" + id;
};

apiClient.interceptors.request.use(
  async (config) => {
    const token = sessionStorage.getItem(SessionStorage.AUTH_TOKEN);
    if (token !== undefined && token !== null) {
      config.headers.Authorization = `Basic ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      const axiosError = new axios.AxiosError(
        error?.message,
        error?.code,
        error?.config,
        error?.request,
        error?.response,
      );
      return Promise.reject(axiosError);
    } else if (error.request) {
      // No response received from server | Network error
      const axiosError = new axios.AxiosError(
        error?.message,
        error?.code,
        error?.config,
        error?.request,
        {
          status: 400,
          data: {
            status: HTTP_STATUS.NETWORK_ERROR,
          },
        } as any,
      );
      return Promise.reject(axiosError);
    } else {
      const axiosError = new axios.AxiosError(
        error?.message,
        error?.code,
        error?.config,
        error?.request,
        {
          status: 400,
          data: {
            status: HTTP_STATUS.UNEXPECTED_ERROR,
          },
        } as any,
      );
      return Promise.reject(axiosError);
    }
  },
);

export default apiClient;
