import { PieChart } from "@mui/x-charts";
import { BarChart } from "@mui/x-charts/BarChart";
import { LineChart } from "@mui/x-charts/LineChart";
// import TitleGraph from "../components/home/title-graph";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import { BASE_URL } from "../api/setup";
import CosButton from "../components/CosButton";
import TitleGraph from "../components/home/title-graph";
import { Constants } from "../constants/home-constants";
import { UserType } from "../types/usuario";
import HomeViewModel, { BrigadistaDese } from "../viewmodel/home";
import "./../components/menu/nav-bar.css";

interface AreaOperacional {
  area_operacional: string | null;
  quantidade_inseridos: string;
}

const Home = () => {
  const { estado, situacao, estatisticas, brigadistaDese, user } = HomeViewModel();

  const getTotal = (dados: any[]) => {
    let total = 0;

    dados.forEach((item: any) => {
      total = total + Number(item.quantidade_inseridos);
    });

    return total;
  };

  const getTotal2 = (dados: BrigadistaDese[]) => {
    let total = 0;

    dados.forEach((item) => {
      total = total + Number(item.total_clientes);
    });

    return total;
  };

  const separarArrays = (dados: any[]) => {
    const areasOperacionais: (string | null)[] = [];
    const quantidadesInseridos: number[] = [];

    dados.forEach((item: any) => {
      areasOperacionais.push(item.area_operacional);
      quantidadesInseridos.push(Number(item.quantidade_inseridos));
    });
    return { areasOperacionais, quantidadesInseridos };
  };

  return (
    <div className="w-full px-[10px] md:px-[15px] py-[8px] relative">
      <div className=" bg-blue-100 flex flex-1 flex-col elevated min-h-[300px]">
        <div className="flex-1 px-[30px]">
          <div className="mt-[40px]">
            <TitleGraph
              title="Gráfico Estatistico (Áreas Operacionais)"
              description="Relatório de Registo de Clientes por Áreas Operacionaiss"
              value={getTotal(estatisticas.value)}
            />
          </div>
          <LineChart
            className="flex-1"
            height={300}
            series={[
              { data: separarArrays(estatisticas.value).quantidadesInseridos },
            ]}
            xAxis={[
              {
                scaleType: "point",
                data: separarArrays(estatisticas.value).areasOperacionais,
              },
            ]}
          />
        </div>
      </div>
      <div className="flex flex-1  flex-col gap-[30px] tablet:flex-row tablet:mt-[30px] tablet:mb-[25px]">
        <div className="flex flex-1 flex-col min-h-[300px] justify-between pt-[55px] pb-[30px] elevated">
          <div className="mx-[30px] py-[10px]">
            <TitleGraph
              title="Gráfico Estatístico (Situação dos Clientes)"
              description="Relatório do Estado dos Clientes"
              value={situacao.total}
            />
          </div>
          <div className="flex flex-1 flex-col justify-center items-center">
            <PieChart
              className="w-100px]"
              series={[
                {
                  data: [
                    {
                      id: 0,
                      value: situacao.Correcto,
                      label: Constants.situacaoCadastro.correcto,
                      color: "#007C00",
                    },
                    {
                      id: 1,
                      value: situacao.Incompleto,
                      label: Constants.situacaoCadastro.incompleto,
                      color: "#FCC900",
                    },
                    {
                      id: 2,
                      value: situacao.Irregular,
                      label: Constants.situacaoCadastro.irregular,
                      color: "#E50212",
                    },
                  ],
                },
              ]}
              height={150}
              width={400}
            />
          </div>
        </div>
        <div className="flex flex-1 flex-col min-h-[300px] justify-between pt-[55px] pb-[30px] elevated">
          <div className="flex flex-1 flex-col px-[20px]">
            <div className="mx-[30px] py-[10px]">
              <TitleGraph
                title="Gráfico Estatistico (Estado do Contador)"
                description="Relatório do Estado dos Contador"
                value={estado.total}
              />
            </div>
            <BarChart
              xAxis={[
                {
                  scaleType: "band",
                  data: [
                    Constants.estadoContador.bom,
                    Constants.estadoContador.leituraInv,
                    Constants.estadoContador.avariado,
                    Constants.estadoContador.semContador,
                  ],
                },
              ]}
              series={[
                {
                  data: [estado.Bom, estado.NaoV, estado.Avariado, estado.Sem],
                },
              ]}
              height={300}
            />
          </div>
        </div>
      </div>
      {
        user?.role != UserType.Brigadista && (
          <div className=" bg-blue-100 flex flex-1 flex-col elevated min-h-[300px] pb-[40px]">
            <div className="flex-1 px-[30px]">
              <div className="mt-[30px]">
                <TitleGraph
                  title="Desempenho dos Brigadistas"
                  description="Desempenho dos brigadistas em relação a quantidade de registros"
                // value={getTotal2(brigadistaDese)}
                />
                <div className=" mt-[30px]">
                  <DenseTable data={brigadistaDese} />
                </div>
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
};

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number,
) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Nome", 159, 6.0, 24, 4.0),
  createData("Email", 237, 9.0, 37, 4.3),
  createData("Telefone", 262, 16.0, 24, 6.0),
  createData("Quantidade de Entrevistas", 305, 3.7, 67, 4.3),
];

interface DenseTableProps {
  data?: BrigadistaDese[];
}

function DenseTable({ data }: DenseTableProps) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell align="center">Nome</TableCell>
            <TableCell align="center">Email</TableCell>
            <TableCell align="center">Telefone</TableCell>
            <TableCell align="center">Entrevistas</TableCell>
            <TableCell align="right">Entrevistados</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            ?.filter((item) => {
              return item.total_clientes != 0;
            })
            ?.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell align="center">{row.nome}</TableCell>
                <TableCell align="center">{row.email}</TableCell>
                <TableCell align="center">{row.telefone}</TableCell>
                <TableCell align="center">{row.total_clientes}</TableCell>
                <TableCell align="right">
                  <Link
                    to={`${BASE_URL}/api/cliente/download/brigadista-data?email=${row.email}`}
                  >
                    <CosButton>BAIXAR</CosButton>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default Home;
