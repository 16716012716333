import { Button } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import { useState } from "react";
import { Link } from "react-router-dom";
import api from "../../api";
import { API_MAP_KEY } from "../../api/setup";
import FiltroMenuClientes from "../../components/filtros/filter-menu-clientes";
import { ModalStateProps } from "../../components/modal";
import { EntrevistadoTipo } from "../../constants/cliente-form";
import { Constants } from "../../constants/home-constants";
import { useBetterState } from "../../hooks/use-better-state";
import { Client } from "../../types/client";
import tasks from "../../utils/tasks";
import MapaGeralViewModel from "../../viewmodel/relatorios/mapa-geral";
const MapaGeral = () => {
  const {
    viewLog,
    clienteState,
    currentView,
    clientes,
    setClientes,
    pageq,
    loadPage,
    loadClientes,
    listenerNav,
    handleClick,
    handleClienteState,
  } = MapaGeralViewModel();

  const deleteData = useBetterState<any>({
    isLoading: false,
    data: 0,
  });
  const modalState = useBetterState<ModalStateProps>({
    show: false,
  });

  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [locationClient, setLocationClient] = useState<Client[]>([]);
  const [moreInfo, setMoreInfo] = useState<{
    street: string;
    position: { lat: number; lng: number }
  }>({
    street: "",
    position: {
      lat: 0,
      lng: 0
    }
  });

  const handleMarkerClick = (latitude: number, longitude: number) => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${API_MAP_KEY}`,
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.results[0]) {
          setMoreInfo({
            position: {
              lat: latitude,
              lng: longitude
            },
            street: data.results[0].formatted_address,
          });
        }
      })
      .catch((error) => console.error("Error fetching geocode data:", error));
  };

  interface Place {
    name: string;
  }

  const [places, setPlaces] = useState<Place[]>([]);

  const handleMarkerLocation = (position: { lat: number; lng: number }) => {
    const service = new window.google.maps.places.PlacesService(null!);

    const request = {
      location: new window.google.maps.LatLng(position.lat, position.lng),
      radius: "100", // 500 meters
      type: ["establishment"],
    } as any;

    service.nearbySearch(request, (results, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        console.log(results);
        setPlaces(
          (results as any).map((place: google.maps.places.PlaceResult) => ({
            name: place.name!,
          })),
        );
      } else {
        console.error("Error fetching places data:", status);
      }
    });
  };

  const loadClientLocation = (latitude: number, longitude: number) => {
    api.get(
      {
        path: "/api/cliente/all/location",
        params: {
          latitude: latitude,
          longitude: longitude,
        },
      },
      (data) => {
        console.log(data);
        if (data.length > 0) {
          setShowDetails(true);
          setLocationClient(data);
          handleMarkerClick(latitude, longitude);
          // handleMarkerLocation({
          //   lat: latitude,
          //   lng: longitude,
          // });
        }
      },
      (erros) => {
        console.log(erros);
      },
    );
  };

  return (
    <div className="w-full px-[10px] md:px-[15px] py-[8px] relative">
      <div className=" bg-blue-100 flex flex-1 flex-col elevated min-h-[300px]">
        <div className="w-full relative">
          {showDetails && (
            <div className="min-h-[800px] absolute top-0 left-0 right-0 w-full z-50 bg-black-50">
              <div>
                <div className="flex flex-1 px-[40px] py-[20px]">
                  <div className="flex flex-1">
                    <span className=" text-[20px] font-bold">{`Clientes | Coordenadas (Lat:${moreInfo.position.lat}, Lng:${moreInfo.position.lng}) ${(moreInfo.street !== "" && moreInfo.street != 'false') && ` - ${moreInfo.street}`}`}</span>
                  </div>
                  <div>
                    <Button
                      onClick={() => {
                        setShowDetails(false);
                      }}
                      variant="contained"
                      style={{ backgroundColor: "#ed1c38" }}
                    >
                      Fechar
                    </Button>
                  </div>
                </div>
                <div>
                  <div className="flex flex-1 px-[40px] py-[20px]">
                    <Paper className="flex-1" sx={{ width: "100%" }}>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Nome Completo</TableCell>
                              <TableCell align="right">contacto 1</TableCell>
                              <TableCell align="right">Bairro</TableCell>
                              <TableCell align="right">
                                Numero Contador
                              </TableCell>
                              <TableCell align="right">
                                Conta Contracto
                              </TableCell>

                              <TableCell align="right">Data Registro</TableCell>
                              <TableCell align="right">Acção</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {locationClient.map((row) => (
                              <TableRow
                                key={row.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {row.nome}
                                </TableCell>
                                <TableCell align="right">
                                  {tasks.verificarValor(
                                    row.contacto1,
                                    "Sem informação",
                                  )}
                                </TableCell>
                                <TableCell align="right">
                                  {tasks.verificarValor(
                                    row.bairro,
                                    "Sem informação",
                                  )}
                                </TableCell>
                                <TableCell align="right">
                                  {tasks.verificarValor(
                                    row.numero_contador,
                                    "Sem informação",
                                  )}
                                </TableCell>
                                <TableCell align="right">
                                  {tasks.verificarValor(
                                    row.conta_contrato,
                                    "Sem informação",
                                  )}
                                </TableCell>
                                <TableCell align="right">
                                  {row.data_registro
                                    ? tasks.convertToGMTPlus2(row.data_registro)
                                    : tasks.verificarValor(
                                      row.data_registro,
                                      "Sem informação",
                                    )}
                                </TableCell>
                                <TableCell align="right">
                                  {
                                    <div className="flex flex-row justify-end gap-[15px]">
                                      <Link
                                        to={
                                          clientes.data.find((item: any) => {
                                            return item.id === row.id;
                                          })?.tipo_registo == EntrevistadoTipo.POTENCIAL
                                            ? `/geral/nav/info-potencial/${row.id}`
                                            : `/geral/nav/info-cliente/${row.id}`
                                        }
                                      >
                                        <Button variant="contained">Detalhes</Button>
                                      </Link>
                                    </div>
                                  }
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-1 px-[40px] pt-[20px] flex-col">
            <div className="flex flex-1 flex-row justify-between flex-wrap items-center">
              <div className="flex flex-1">
                <span className=" text-[20px] font-bold">
                  Lista de Clientes
                </span>
              </div>
            </div>
            <div>
              <FiltroMenuClientes
                valorPesquisa={clienteState.value.value}
                handleChangeNc={handleClienteState}
                listener={(state, dropdownNav, email) => {
                  // updateLinkExcel(state);
                  listenerNav(state, dropdownNav, email);
                }}
                onClick={handleClick}
              />
            </div>
            <div className="w-full flex flex-1">
              {viewLog.show && (
                <div className="border-[1px] border-blue-800 w-full flex flex-row justify-center items-center mx-[5px] mb-[5px] rounded-[5px]">
                  <p className="text-center">{viewLog.message}</p>
                </div>
              )}
            </div>
          </div>
          <div className="w-full px-[20px] overflow-auto mb-[30px] tablet:px-[30px]">
            <div className="w-full h-[500px]">
              <APIProvider
                apiKey={API_MAP_KEY}
                onLoad={() => {
                  console.log("Mapa carregado !!!");
                }}
              >
                <Map
                  defaultZoom={8}
                  defaultCenter={{ lat: -25.9566848, lng: 32.5790043 }}
                >
                  {clientes.data.map((item, index) => {
                    return (
                      <Marker
                        onClick={() => {
                          loadClientLocation(
                            Number(item.latitude),
                            Number(item.longitude),
                          );
                        }}
                        title={`${item.nome} - ${tasks.verificarValor(item.conta_contrato, "-") === "-" ? `contacto: ${item.contacto1}` : `conta contrato: ${item.conta_contrato}`}`}
                        position={{
                          lat: Number(item.latitude),
                          lng: Number(item.longitude),
                        }}
                        icon={getPinColor(
                          item.tipo_registo,
                          item.situacao_entrevistado,
                          item.suspeita_ilegal,
                        )}
                      />
                    );
                  })}
                </Map>
              </APIProvider>
            </div>
            <div className="mt-[20px] flex flex-1 justify-end">
              <Pagination
                count={clientes.pagination.totalPages}
                color="primary"
                onChange={(event: React.ChangeEvent<unknown>, page: number) => {
                  loadPage(page);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const getPinColor = (tipo: string, situacao: string, suspeita: string) => {
  if (tipo === EntrevistadoTipo.POTENCIAL && suspeita === "Sim") {
    return "http://maps.google.com/mapfiles/ms/icons/red.png";
  } else if (tipo === EntrevistadoTipo.POTENCIAL && suspeita === "Não") {
    return "http://maps.google.com/mapfiles/ms/icons/purple.png";
  }

  if (situacao === Constants.situacaoCadastro.correcto) {
    return "http://maps.google.com/mapfiles/ms/icons/green.png";
  } else if (situacao === Constants.situacaoCadastro.irregular) {
    return "http://maps.google.com/mapfiles/ms/icons/red.png";
  } else if (situacao === Constants.situacaoCadastro.incompleto) {
    return "http://maps.google.com/mapfiles/ms/icons/yellow.png";
  }
};

export default MapaGeral;
